import React from "react"
import Player from "../../../blocks/en/player"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  player: playersYaml(slug: { eq: "saveljevs" }) {
    ...PlayerInfoEn
  }
  stats: allStatsYaml {
    nodes {
      key
      value_en
      average
    }
  }
}`

export default class Saveljevs extends Player {
  renderDescription() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>
          A midfield player with excellent vision of the field and reading the game. Able to give a pass of any length, dribble past the opponent, has the skills of a playmake and is quite capable in tackling and pressing.
          </p>
          <img src="/images/players/saveljevs/photo1.jpg" alt={ this.fullName() } />
          <p>
          Covers the ball well with his body, always looking for a quick way to go into a counterattack, is able to organize a fast break with his first touch.
          </p>
          <img src="/images/players/saveljevs/photo2.jpg" alt={ this.fullName() } />
          <p>
          By reading the game well, he's able to provide cover for partners in in the midfield, trying to quickly turn around facing the opponent goal. Always presses a loss or tries to quickly return to his position.
          </p>
          <img src="/images/players/saveljevs/photo3.jpg" alt={ this.fullName() } />
          <p>
          One of the leaders of the youth team of Latvia, has already received his first call-up to the first national team. He's a regular player of the Primavera team of Verona, is a vice-captain. Plays for the first team of Verona as well.
          </p>

          <div className="video">
            <iframe
              src="https://www.youtube.com/embed/_xNKkyttqrE"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>

        </div>
      </div>
    )
  }
}